import { Factory } from 'fishery';
import { v4 as uuid } from 'uuid';
import { faker } from '@faker-js/faker';
import { IUser } from '../types/user';

export const userFactory = Factory.define<IUser>(() => ({
  _modelName: 'worker',
  accountIsLocked: false,
  accountProps: {
    completionScore: -1,
    emailCommunicationEnabled: true,
    hasPaymentsPendingOnAcctSetup: false,
    invitePending: false,
    invitingCompany: undefined,
    isDemoUser: false,
    lastSignUpStep: 'Done',
    messageDetailsTooltip: false,
    messageTimestampTooltip: false,
    onboardingCompletedAt: new Date(),
    onboardingRequired: false,
    preferredChatType: 'inapp',
    reenabledCompany: undefined,
    sendSms: true,
    unsubscribedMsids: [],
    verifySignupSmsSentCount: 2,
  },
  accountStatus: 'active',
  address: {
    city: 'New York',
    state: 'NY',
    street1: '2110 Main Dr.',
    street2: '',
    zip: '10007',
  },
  addressString: '2110 Main Dr, New York, NY 10007, USA',
  adherence: 0,
  appVersion: {
    build: '1',
    platform: 'ios',
    version: '1.13.1',
  },
  assignedSonarNumber: undefined,
  authorizedDevices: [],
  branchParams: {},
  callRecordingReviews: [],
  canAccessPII: false,
  certs: [],
  checkrId: undefined,
  companies: [],
  companyStatus: [],
  contractorAgreement: {
    accepted: true,
    acceptedAt: new Date(),
    agreementURL: 'https://example.com',
  },
  createdAt: new Date(),
  deletedAt: undefined,
  deletedBy: undefined,
  disabledAt: undefined,
  disabledBy: undefined,
  displayName: 'TestMatch TostMatch',
  dob: String(faker.date.birthdate({ max: 100, min: 18, mode: 'age' })),
  education: [],
  email: faker.internet.email(),
  failedLoginAttempts: 0,
  firstName: 'TestMatch',
  flaggedAt: undefined,
  fraudViolations: [],
  geohash: '9v6s9wsjv',
  h3Geohash: '86489e22fffffff',
  invitePending: false,
  isDeleted: false,
  isDisabled: false,
  isFlagged: false,
  isPromoted: false,
  isVerified: true,
  knownAssociates: [],
  languagePreference: 'en',
  lastActiveAt: new Date(),
  lastName: 'TostMatch',
  loc: {
    coordinates: [-97.6627163, 30.3589769],
    type: 'Point',
  },
  localeInfo: {
    country: 'US',
    defaultCurrency: 'usd',
    language: 'en',
    timezone: 'America/Chicago',
  },
  locations: [],
  mfaTokenExpires: new Date(),
  mfaTokenSecret: '',
  modelName: 'worker',
  needsReview: false,
  onboardingQuestionResponse: [{}],
  password: '$2a$10$V63O.3ru9nPof70mHV3CNOyb6D1umj3CuaGoioTS6aVhcZccUvHYG',
  passwordExpiresAt: new Date(),
  passwordHistory: [
    {
      changedAt: new Date(),
      hashedPassword:
        '$2a$10$V63O.3ru9nPof70mHV3CNOyb6D1umj3CuaGoioTS6aVhcZccUvHYG',
    },
  ],
  phoneCarrierInformation: {
    name: 'shiftsmart-telecom',
    serviceType: 'cell',
  },
  phoneNumber: faker.phone.number('+1##########'),
  pools: [],
  position: undefined,
  profileAttributes: {},
  profileImageURL: faker.image.avatar(),
  profileVideoURL: faker.image.avatar(),
  // provider: 'phone',
  rating: 0,
  ratingCt: 0,
  referralAgreement: {
    accepted: false,
  },
  referralLink: null,
  referrerUUID: null,
  reliabilityScore: 0,
  resetExpires: undefined,
  resetShortToken: undefined,
  resetToken: undefined,
  resumeImageURL: null,
  roles: ['user', 'worker'],
  statusLog: [
    {
      prop: 'foo',
      time: new Date(),
      user: uuid(),
      val: '',
    },
  ],
  termsOfUse: [],
  unbounceParams: {},
  updatePWHash: false,
  updatedAt: new Date(),
  userDocuments: [],
  userName: undefined,
  username: faker.internet.userName(),
  utmParams: {},
  uuid: uuid(),
  verificationDocuments: {
    back: '1',
    front: '2',
  },
  verifyChanges: {},
  verifyExpires: undefined,
  verifyShortToken: undefined,
  verifyToken: undefined,
  workerReferralId: undefined,
}));
