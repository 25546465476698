import type { IUser } from './user';
import type { ICompany } from './company';
import { IShift } from './shift';
import { ISite } from './site';
import { IBaseSchema, ITimestampedItem } from './_base';
import { UUID } from './utils';

export interface IAuditLog extends ITimestampedItem, IBaseSchema {
  changedData: Record<string, unknown>;
  collectionName?: string;
  company?: Pick<ICompany, 'uuid' | 'name'>;
  companyId?: string;
  ipAddress?: string;
  methodName?: string;
  targetId?: UUID;
  user?: Partial<IUser>;
  userId?: IUser['uuid'];
}

export interface IAuditLogShiftHistory extends IAuditLog {
  changedData: {
    details?: Record<string, unknown>;
    text: string;
  };
  shiftObjects?: Array<Pick<IShift, 'uuid' | 'title'>>;
  siteObjects?: Array<Pick<ISite, 'uuid' | 'name' | 'loc'>>;
  userObjects?: Array<Pick<IUser, 'uuid' | 'displayName' | 'profileImageURL'>>;
}

export const shiftHistoryLogEntities = ['user', 'shift', 'site'] as const;

export type ShiftHistoryLogEntity = (typeof shiftHistoryLogEntities)[number];
