import type { IUserCert } from './cert';
import type { IPool } from './pool';
import type { IBaseItem, ITimestampedItem } from './_base';
import type { IPoint } from './_mixins';
import type { Timestamp, UUID } from './utils';
import type { ICompany } from './company';
import type { IAddress } from './address';
import type { ISite } from './site';

/**
 * Role Type
 * Each role describes access to a certain set of resources.
 *
 * admin - Access to the admin panel
 * company - Access to employer portal & employer app, restricted by agent props
 * employer - Access to the employer portal & employer app
 * engineer - TBD
 * monitoring-agent - Access to application telemetry.
 * super-admin - Access to superpowers
 * worker - Access to the worker app
 * user - Controls whether the user can log-in
 */
export type UserRole =
  | 'accounts-central-admin'
  | 'accounts-central-approver'
  | 'accounts-central-user'
  | 'admin'
  | 'apiUser'
  | 'company-agent'
  | 'customer'
  | 'employer'
  | 'engineer'
  | 'external'
  | 'monitoring-agent'
  | 'notifications-viewer'
  | 'payment'
  | 'super-admin'
  | 'user'
  | 'worker';

/**
 * ### Company Status Role
 * Defines the user's relationship and permissions to a specific company
 *
 * - `owner` (employer): The company's primary account holder
 * - `admin` (employer) Can edit most, if not all company settings
 * - `manager` (employer) "reserved for future use"
 * - `agent` (employer) Base "Agent" allows a user to log in with the company
 * - `survey-review` (employer): User is restricted to reviewing campaign surveys
 * - `supervisor` (employer) Allows for editing of all shift fields and statuses
 * - `employee` (worker) User  sis a "Worker" for this company
 * - `temp` (worker) unknown
 * - `contractor` (worker) unknown
 * - string; :shrug:
 */
export type CompanyStatusRole =
  /** 'owner' employer: The company's primary account holder */
  | 'owner'
  /** 'admin' employer: Can edit most, if not all company settings */
  | 'admin'
  /** 'manager' employer: "reserved for future use" */
  | 'manager'
  /** 'agent' employer: Base "Agent" allows a user to log in with the company */
  | 'agent'
  /** 'survey-review' employer: User is restricted to reviewing campaign surveys */
  | 'survey-review'
  /** 'supervisor' employer: Allows for editing of all shift fields and statuses */
  | 'supervisor'
  /** 'employee' worker: User is a "Worker" for this company */
  | 'employee'
  /** 'temp' worker: unknown */
  | 'temp'
  /** 'contractor' worker: unknown */
  | 'contractor'
  /** string; :shrug: */
  | string;

export type UserAccountStatus =
  | 'active'
  | 'deactivated'
  | 'suspended'
  | 'warned';

type DocumentType =
  | 'driverLicense'
  | 'passport'
  | 'stateId'
  | 'covidVaccinationProof'
  | 'covidTestProof'
  | 'I9Document'
  | 'carInsurance';

export type CompanyStatusRecord = {
  attendanceViolationCount: number;
  attendanceViolationCountManualTimestamp?: Date;
  autoPortedFrom?: string;

  /** @depreacted not used (in a very long time) */
  candidateRef?: string;
  company: ICompany['uuid'];
  endDate?: Date;
  externalId?: string;
  externalId2?: string;
  isConsequencesDeactivated?: boolean;

  name?: ICompany['name'];
  /**
   * Set up when a worker rejects an onboarding
   */
  noOnboardings?: boolean;

  noOnboardingsReason?: string;

  onboardingVideoQuestionResponses?: Array<{
    question: string;
    url: string;
    videoDurationSeconds: number;
  }>;

  opportunityOpeningRef?: string;

  path: string;
  payment?: Array<{
    allowedPercent: number;
    amount: number;
    calcBy: 'hourly' | 'shift';
    effectiveDate: Date;
    location: string;
    percent: number;
    rate: number;
    role: string;
    validFrom: Date;
    validTo: Date;
  }>;
  reactivateOn?: Timestamp;
  /** @deprecated use `roles` instead */
  role?: CompanyStatusRole;
  /**
   * eg: 'admin', 'manager', 'agent'
   *
   * Configured Options:
   * `survey-review`: Restricted account for reviewing campaign work
   */
  roles?: Array<CompanyStatusRole>;
  // access?: any[];
  route?: string;
  showReenableWorkerConsequencesModal?: boolean;
  smsConfig?: {
    lastAvailableShiftActionMessageSentAt: Date;
    lastBulkInitialShiftMessageSentAt: Date;
    lastNewShiftActionMessageSentAt: Date;
  };

  /**
   * Fields for auto-porting
   * source: tracks how the user got to this company (for example, "autoport")
   * autoPortedFrom: if autoported, the company ID of the company they originated from
   */
  source?: boolean;

  startDate?: Date;
  status:
    | 'candidate'
    | 'declined'
    | 'rejected'

    // TODO: Migrate to roles
    | 'temp'
    | 'contractor'
    | 'employee'

    // "general" statuses
    | 'pending'
    | 'active'
    | 'inactive'
    | 'former';
  statusChangeReason?: string;
  statusLog?: [
    {
      logType: string;
      message: string;
      props: unknown;
      reason?: string;
      reasonCode?: string;
      status: string;
      time: Date;
      user: string;
    },
  ];
} & ITimestampedItem;

export type UserAccountProps = {
  /** #### atlasSearchEnabledServices
   * @deprecated This setting is a temporary feature flag for enabling atlas search
   * for specific users on specific services. It will be removed soon.
   */
  atlasSearchEnabledServices?: string[];
  availableForWorkInfoModalSeen?: boolean;
  completionScore?: number;
  disableSpoofedLocationCheck?: boolean;
  emailCommunicationEnabled?: boolean;
  enableAssessment?: boolean;
  hasPaymentsPendingOnAcctSetup?: boolean;
  hasSeenBundlesTooltip?: boolean;
  hasSeenRecommendedStoresFilterTooltip?: boolean;
  hasSeenReferralTutorial?: boolean;
  hasSeenSecurityBadgeWalkThrough?: boolean;
  hasUserAcceptedNewCancellationPolicy?: boolean;
  invitePending?: boolean;
  invitingCompany?: string;
  isDemoUser?: boolean;
  lastSignUpStep?: string;
  messageDetailsTooltip?: boolean;
  messageTimestampTooltip?: boolean;
  msa?: string;
  needsReview?: boolean;
  onboardingCompletedAt?: Date;
  onboardingRequired?: boolean;
  preferredChatType?: 'sms' | 'inapp';
  ratingRequestedAt?: Date;
  reenabledCompany?: string;
  requestRatingOnAppOpen?: boolean;
  sendSms?: boolean;
  signupSource?: 'web' | 'mobile';
  smsBlocked?: boolean;
  unsubscribedMsids?: string[];
  verifySignupSmsSentCount?: number;
};

export interface UserAgreement {
  accepted?: boolean;
  acceptedAt?: Date;
  agreementURL?: string;
}

export const deactivationExternalReasons = [
  'billing',
  'checkInCheckOutIssues',
  'criminalBehavior',
  'inappropriateBehaviorTeachable',
  'inappropriateBehaviorUnacceptable',
  'injuryAccommodationRequest',
  'lateArrival',
  'noShow',
  'noWorkCompletion',
  'other',
  'partialWorkCompletion',
  'partnerTurnaway',
  'positiveFeedback',
  'scheduleRelatedRequests',
] as const;
export type DeactivationExternalReason =
  (typeof deactivationExternalReasons)[number];

export const targetTypes = ['company', 'site'] as const;
export type TargetType = (typeof targetTypes)[number];

export interface Deactivation {
  createdAt?: Date;
  deactivatedBy?: IUser['uuid'];
  deactivationExternalReasons: DeactivationExternalReason[];
  deactivationInternalReason: string;
  reactivateOn?: Date;
  targetId?: ICompany['uuid'] | ISite['uuid'];
  targetType?: TargetType;
  updatedAt?: Date;
  uuid: UUID;
}

export interface DeactivationWithJoinedTargets extends Deactivation {
  operatorObj: Pick<IUser, 'displayName' | 'profileImageURL' | 'uuid'>;
  targetObj:
    | (Pick<ICompany, 'uuid' | 'name'> & {
        images: Pick<ICompany['images'], 'avatar'>;
      })
    | Pick<ISite, 'loc' | 'name' | 'uuid'>;
}

export interface IUser extends IBaseItem {
  accountDeactivatedAt?: Date;
  accountDeactivatedBy?: string;
  accountDeactivatedReason?: string;
  accountIsLocked: boolean;
  accountLockedAt?: Date;
  accountProps: UserAccountProps;
  accountReactivatedAt?: Date;
  accountStatus: UserAccountStatus;
  accountSuspendedAt?: Date;
  address?: {
    city?: string;
    state?: string;
    street1?: string;
    street2?: string;
    zip?: string;
  };
  addressString: string;
  /** @deprecated */
  adherence: number;
  appVersion: {
    build: string;
    platform: string;
    version: string;
  };
  authorizedDevices: Array<{
    appBuild: string;
    appPermissions: Record<string, string>;
    appVersion: string;
    associatedAccounts?: string[];
    deviceId: string;
    deviceName: string;
    deviceType: string;
    fingerprint: string;
    ipAddress: string;
    isFlagged: boolean;
    lastActiveAt?: Date;
    notificationAddress: string;
    platform: string;
  }>;
  branchParams: unknown;
  callRecordingReviews?: Array<{
    company: string;
    result: string;
    reviewedAt: Date;
    reviewedBy: string;
    rubricResults: unknown;
    uuid: UUID;
  }>;
  canAccessPII?: boolean;
  certs: Array<IUserCert>;
  checkrId?: string;

  companies: ICompany['uuid'][];
  companyStatus: CompanyStatusRecord[];
  contractorAgreement: UserAgreement;
  deactivations?: Deactivation[];
  deletedAt?: Date;
  deletedBy?: string;
  disabledAt?: Date;
  disabledBy?: string;
  displayName?: string;
  /** DOB: format YYYY-MM-DD */
  dob?: string;

  education: Array<{
    additionalInfo: string;
    degreeOrField: string;
    end: Timestamp;
    schoolName: string;
    start: Timestamp;
    uuid: UUID;
  }>;
  email: string;

  failedLoginAttempts: number;
  firstName: string;
  flaggedAt?: Date;
  fraudViolations?: Array<IFraudViolation>;
  geohash: string;
  h3Geohash: string;
  isAdmin?: boolean;
  isDeleted: boolean;
  isDisabled: boolean;
  isFlagged: boolean;
  isVerified: boolean;

  /** A list of users who have been  */
  knownAssociates: Array<IKnownAssociate>;

  languagePreference?: string;
  lastActiveAt: Date;
  lastName: string;
  loc: IPoint;
  localeInfo: {
    country: string;
    defaultCurrency: string;
    language: string;
    timezone: string;
  };
  /** The UUIDs of all locations where the partner has completed a shift (or shop?) */
  locations: IAddress['uuid'][];

  mfaTokenExpires: Date;
  mfaTokenSecret: string;
  needsReview: boolean;
  /** #### offsiteCheckinOverrides
   * Keeps track of the number of times a partner has bypassed the checkin code requirement offsite */
  offsiteCheckinOverrides?: number;
  onboardingQuestionResponse: [
    {
      body?: string;
      company?: string;
      createdAt?: Date;
      title?: string;
      updatedAt?: Date;
      uuid?: UUID;
      videoURL?: string;
    },
  ];

  password: string;
  passwordExpiresAt: Date;
  passwordHistory: {
    changedAt: Date;
    hashedPassword: string;
  }[];
  phoneCarrierInformation: {
    name: string;
    serviceType: string;
  };
  phoneNumber: string;
  pools: Array<IUserPool>;
  profileAttributes?: {
    accessToVehicle?: string;
    dayOfWeekPreference?: Array<string>;
    employmentStatus?: string;
    frequency?: string;
    workTimePreference?: Array<string>;
  };
  profileImageURL: string;
  profileVideoURL: string;

  referralAgreement: UserAgreement;
  referralLink: string | null;
  referrerUUID: string | null;
  /** @deprecated */
  reliabilityScore: number;

  requireMFA?: boolean;
  requirePasswordRotation?: boolean;
  resetExpires?: Date;

  resetShortToken?: string;
  resetToken?: string;
  resumeImageURL: string | null;
  rewardsTier?: RewardsTier;

  /** Determines which apps the account can login to. eg: must have the `worker` role to log into the worker app */
  roles: UserRole[];
  statusLog?: [
    {
      prop: string;
      time: Date;
      user: string;
      val: unknown;
    },
  ];
  termsOfUse: Array<UserAgreement>;
  unbounceParams?: {
    [key: string]: unknown;
    country?: string;
    email?: string;
    name?: string;
    phoneNumber?: string;

    requestEnd?: string;
    requestRole?: string;
    requestSlots?: string;
    requestStart?: string;
    zipCode?: string;
  };
  updatePWHash: boolean;
  userDocuments?: Array<{
    back: string;
    documentType: DocumentType;
    expirationAt: Date;
    front: string;
    uuid: string;
    verificationStatus: string;
  }>;
  /** @deprecated never has really been used
   * value will be coerced to lowercase and trimmed
   */
  username: string | null;

  utmParams?: Record<string, unknown>;

  uuid: UUID;

  verificationDocuments?: {
    back: string;
    front: string;
  };
  verifyChanges?: Record<string, unknown>;
  verifyExpires?: Date;
  verifyShortToken?: string;
  verifyToken?: string;

  w2PayoutsEnabled?: boolean;
  workerReferralId?: string | null;
  workosId?: string;
}

export interface IUserPool
  extends Pick<
    IPool,
    'uuid' | 'label' | 'company' | 'companyPath' | 'companies' | 'poolType'
  > {
  addedAt: Date;
}
export type IWorker = IUser;

export interface IKnownAssociate extends ITimestampedItem {
  association: 'self' | 'family' | string;
  isAllowed: boolean;
  userId: IUser['uuid'];
}

export type RewardsTier = {
  beginsAt?: Date;
  currentBenefits?: unknown[];
  expiresAt?: Date;
  firstAddedAt?: Date;
  informationalBannerShownAt?: Date;
  previousTierName?: 'gold' | 'silver';
  tierName?: 'gold' | 'silver';
  tieredBannerShownAt?: Date;
};

export type FraudViolationType =
  | 'phoneCarrier'
  | 'bannedCountry'
  | 'associatedAccounts'
  | 'bannedDevice'
  | 'deviceLimit'
  | 'spoofedLocation'
  // TODO: do we want an `other`?
  | 'other';

export interface IFraudViolation {
  createdAt: Date;
  fraudReason: string;
  fraudType: FraudViolationType;
  isResolved?: boolean;
  params?: {
    [key: string]: unknown;
  };
  resolvedAt?: Date;
  resolvedBy?: IUser['uuid'];
  resolvedReason?: string;
  updatedAt: Date;
  uuid: UUID;
}
