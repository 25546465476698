import type { PartialDeep } from 'type-fest';
import type { IBaseItem, ITimestampedItem } from './_base';
import type { IUser } from './user';
import type { IStatusBasedItem } from './generic';
import type { ITag } from './cert';
import type { IPoint } from './_mixins';
import type { UUID } from './utils';
import type {
  DynamicBonusCompanyConfig,
  DynamicDispatchConfig,
} from './address';
import type { IDispatchPref } from './dispatchPref';

export interface CompanyAgent extends ITimestampedItem {
  permissions?: string[];
  role?: string;
  route?: string;
  status?: string;
  user?: UUID;
}

export interface ICompany extends IBaseItem, IStatusBasedItem {
  abbr: string;
  about: string;
  // TODO: Determine if making these optional is the best approach
  address: {
    city?: string;
    state?: string;
    street1?: string;
    street2?: string;
    zip: string;
  };
  agents: Array<CompanyAgent>;
  customMetrics?: Array<{
    dataType: string;
    defaultValue: number;
    description: string;
    enablePools: boolean;

    key: string;
    label: string;
    searchOptions: {
      range: {
        max: number;
        min: number;
        step: number;
      };
      renderer: string;
    };
    uuid: UUID;
  }>;
  formattedAddress: string;
  images: {
    avatar: string;
    banner: string;
    headerColor: string;
    logo: string;
    square: string;
  };
  industries: unknown[];
  isShiftsmartManaged?: boolean;
  limits: Array<{
    dayCountLimit: number;
    dayHourLimit: number;
    hourLimit: number;
    period: 'weekly' | 'biweekly' | 'monthly';
    role: string;
    shiftLimit: number;
    uuid: UUID;
  }>;

  loc: IPoint;

  localeInfo: {
    country: string;
    defaultCurrency: string;
    language: string;
    timezone: string;
  };
  messagingNumber?: string;
  messagingServiceSid: string;
  modules: CompanyModules;
  name: string;

  notificationSettings: {
    job: boolean;
    remindInterval: number;
    shift: boolean;
    survey: boolean;
    swap: boolean;
    task: boolean;
  };
  owner: IUser['uuid'];

  parentCompanyId?: ICompany['uuid'];
  path: string;
  phoneNumber?: string;
  positions?: Array<ITag>;
  rawInfo: unknown;

  referralLink: string;
  reportingCompanyId?: ICompany['uuid'];

  settings?: CompanySettings;

  shiftRatingOptions: Array<{
    text: string;
    uuid: UUID;
  }>;

  sourcingTags: Array<{
    certType: string;
    title: string;
    uuid: UUID;
  }>;

  status: 'unclaimed' | 'draft' | 'pending' | 'active' | 'archived';
  twilioConversationsMessagingNumber?: string;
  twilioConversationsMessagingServiceSid: string;
  uuid: UUID;
}

export type Tier = {
  icon: string;
  index: number;
  isActive: boolean;
  isVisible: boolean;
  key: string;
  label: string;
};

export type CompanyModules = {
  bulkUpload?: boolean;
  callcenter?: boolean;
  chat?: boolean;
  core?: boolean;
  dispatch?: boolean;
  dispatchPrefs?: boolean;
  inShiftTasks?: boolean;
  inspections?: boolean;
  map?: boolean;
  onboarding?: boolean;
  payments?: boolean;
  poolUpload?: boolean;
  recurringSchedules?: boolean;
  retail?: boolean;
  shifts?: boolean;
  sourcing?: boolean;
  trainings?: boolean;
};

export type CompanySettings = PartialDeep<{
  assignments: {
    sendAssignmentsChunkSize: number;
  };
  automaticPort: {
    originCompanies: Array<string>;
    partnerType: string;
  };
  bonus: BonusConfig;
  callCenterReview: {
    failTag: unknown;
    passTag: unknown;
    pools: unknown;
    rubric: unknown;
  };
  chat: {
    enableConversations: boolean;
    enableForceSMS: boolean;
    enableKeyboardShortcuts: boolean;
    enableManagerChat: boolean;
    enableMessagingCampaigns: boolean;
    enablePartnerToPartner: boolean;
    /** Allows admin users to create and save new Saved Filters */
    enableSavedFilters?: boolean;
    enableSupportChats: boolean;
    onlyAllowChatForLiveShifts: boolean;
    timeBufferForLiveShifts: number;
  };
  company?: {
    allUsersRequireMFA?: boolean;
    allUsersRequirePasswordRotation: boolean;
    primaryContactInfo?: {
      email?: string;
      phoneNumber?: string;
    };
  };
  dashboard: {
    fillRateThreshold: number | string;
    laborCostsThreshold: number | string;
  };
  dispatch: {
    allowDynamicPoolDispatch: boolean;
  };
  employmentStatus: {
    enableInlineBan?: boolean;
  };
  /**
   * ## featureFlags
   * Assorted booleans to enable new features for a limited audience. These
   * should be used for temporary feature testing upon rollout or during
   * UAT. The goal of all feature flags is to either be migrated to a domain
   * specific config or be enabled by default.
   *
   * For features and configurations that will be a more 'permanent' configuration
   * for specific companies, look into the top level domain configs (ie: shifts, things,
   * partners, etc) as a better place.
   */
  featureFlags: {
    companyShiftLimit?: number;
    dashboardConfirmationStats: boolean;
    dashboardStatsEnabled: boolean;
    disableAvailableShiftsAfterAcceptingFirstShift: boolean;
    /**
     * Allows employers to see the workers bonuses in the partner details in the portal
     */
    enableBonusVisibilityInEmployerPortal?: boolean;
    enableDocumentVault?: boolean;
    enableMysteryShopping?: boolean;
    /**
     * Show workers position opportunities from this company in the worker app
     */
    enableOtherOpportunities?: boolean;
    enablePartnerAttributeCollection: boolean;
    /**
     * Show reliability score banner from this company in the worker app
     */
    enableReliabilityScoreBanner: boolean;
    enableResourceCenter?: boolean;
    enableRosterView?: boolean;
    enableSchedulingTemplateCSVUploader: boolean;
    enableServerWorkerClusters: boolean;
    // TODO: Remove once experiment is over EXP 8-31-2023
    enableTemporal: boolean;
    /**
     * ### indexCustomMetrics
     *
     * When enabled, dynamic indexes will be generated on the `userStats`
     * collection for each Custom User Metric configured for the company.
     *
     * @see ICompany['customMetrics']
     *
     * @type {boolean}
     */
    indexCustomMetrics: boolean;
    partnerEmploymentStatus: boolean;
    paymentPendingNotification?: boolean;
    schedulingTemplates: boolean;
    unassignOnTimeOffApproval: boolean;
  };
  notifications: {
    bulkNextDayShiftsTime: boolean;
    bulkNextDayShiftsTimezone: string;
    bulkShiftConfirmFollowupTime: Date;
    bulkShiftConfirmTime: Date;
    bulkWeeklyShiftsTime: Date;
    bulkWeeklyShiftsTimezone: string;
    enableBulkNextDayShiftsInvite: boolean;
    enableBulkShiftConfirm: boolean;
    enableBulkWeeklyShiftsInvite: Date;
    enableDailyUnresolvedChats: boolean;
    enableFillRateThresholdDropped: boolean;
    enableFillRateThresholdReached: boolean;
    enableFilledShift: boolean;
    enableLateToShift: boolean;
    enableNotCheckedOutShift: boolean;
  };
  overtimeSources: string;
  partnerOnboarding: {
    circleKOnboardingOverwrite: boolean;
    enableCommutePreference: boolean;
    enableContractorAgreement: boolean;
    enableNewBackgroundCheckFlow: boolean;
    enableNewOnboardingFlow: boolean;
    enableWaitingList: boolean;
    enableWorkHistory: boolean;
  };
  partners: {
    enableHarbourBackgroundCheck: boolean;
    enableLanguageSwitch: boolean;
    enableSMSInvite: boolean;
    primaryPartnerProfileMetric?: string | null | 'rating';
    useCheckrForBackgroundCheck: boolean;
  };
  payments: {
    /* The interval at payment auto processing should run */
    autoSubmitCron: string;
    enableAsyncUploads: boolean;
    /* Allow payments to be auto processed based on defined logic */
    enableAutoPaymentProcessing: boolean;

    /**
     * ### enablePaymentReviewAndDispute
     *
     * Allows partners to review and dispute payment details after the end of each shift for ssm companies
     */
    enablePaymentReviewAndDispute?: boolean;
    /** @deprecated temporary flag to include `payment.bonusAmount` in the calculation of a transaction amount */
    includeCalcdBonusAmount: boolean;
    /* Automated Payment Processing: Max hours worked we can auto-approve. If hours worked is greater than this value, we'll set it to review */
    maxHoursWorked: number;
    /* What timezone should the interval run in */
    timezone: string;
  };
  pools: {
    enableBulkUpload: boolean;
    poolSizeForUIWarning: number;
  };
  redundancySettings?: RedundancySettings;
  scheduleUploader: string;
  /**
   * ### shiftDispatch Settings
   * Settings for new shift dispatch logic
   */
  shiftDispatch?: {
    automatedShiftDispatchConfig?: Array<{
      hexDistance?: number;
      key: string;
      previouslyWorkedAtLocation?: boolean;
      priority?: number;
      qualityMetricRange?: [number, number];
      reliabilityMetricRange?: [number, number];
      signupDateDelta?: [number, number];
    }>;
    // disables auto canceling related redundant shifts after base shift is manually canceled
    disableAutoCancelRelatedRedundantShifts?: boolean;
    /**
     * @deprecated use settings.bonus.dynamicBonus instead
     */
    dynamicBonuses?: DynamicBonusCompanyConfig;
    /**
     * @deprecated use settings.bonus.dynamicBonus.enabled instead
     */
    dynamicBonusesEnabled?: boolean;

    /** ### enableDynamicDispatch
     * Enables logic to give highest ranked partners a preferred window to accept a shift. @see SSM-1512
     */
    enableDynamicDispatch?: boolean;

    manualRedundantCreationEnabled?: boolean;
    newPartnerInviteRadius?: number;

    newPartnerRedundancyEnabled?: boolean;
    // Opportunities
    opportunities?: {
      /**
       * #### opportunities.eligible
       * If true, the company has been technically configured to enable opportunities by having the onboarding tags set up.
       */
      eligible?: boolean;
      /**
       * #### opportunities.enabled
       * If true, opportunities are turned on for all stores in the company.
       */
      enabled?: boolean;
    };
    /** Configuration for Preferred Shift Relaunch */
    preferredRelaunch: DynamicDispatchConfig;
    // Custom Metric used to assess partner quality
    qualityMetricPath?: string;
    redundantShiftConfig?: Array<{
      dispatchPref: string;
      slots: number;
      titleSuffix: string;
    }>;
    // reliability threshold to filter for low reliability partners
    redundantShiftPartnerReliabilityThreshold?: number;
    redundantShiftTitlePattern?: string;
    // Custom Metric used to assess partner reliability
    reliabilityMetricPath?: string;
    savedLocationEVFRThresholdPath?: string;
    savedLocationNumberOfRedundanciesPath?: string;
    /**
     * enableCancelRedundantUnconfirmedShifts
     * If enabled, will schedule job 6 hours before the shift starts to cancel
     * low reliability workers that have not confirmed
     */
    scheduleCancelRedundantUnconfirmedShifts?: boolean;
    // Top level setting if scheduling redundant shift
    scheduleRedundantShifts?: boolean;
  };
  shifts: {
    /**
     * #### allowBackupShifts
     *
     * If this setting is enabled, users can upload a field that links new shifts
     * to existing shifts as the base
     *
     */
    allowBackupShifts: boolean;
    /**
     * #### allowConcurrentCheckins
     *
     * If this setting is enabled, partners can checkin to this shift even if
     * they are currently checked into another shift
     */
    allowConcurrentCheckins: boolean;
    autoInvitePartners: boolean;
    /**
     * ### bulkPushBackFlexibleShiftsHoursToPush
     *
     * If this value is set, sets how much to increment the shifts' start and end times by
     * each time the push back flexible shifts job runs
     * If not set, defaults to pushing back shifts by 1 hour
     */
    bulkPushBackFlexibleShiftsHoursToPush: number;
    /**
     * ### bulkPushBackFlexibleShiftsLatestTime
     *
     * If this value is set, sets the hour, in local time, that the
     * push back flexible shifts job will push the shift end time to at the latest
     * If not set, defaults to 22 hours (10pm)
     */
    bulkPushBackFlexibleShiftsLatestTime: number;
    calcUserStats: boolean;
    companyCancelReasons: Array<string>;
    confirmationConsequencesMessageEnabled?: boolean;
    /**
     * #### createMobileDeepLink
     *
     * If the setting is enabled, a unique, shareable deep link will be created for the shift.
     */
    createMobileDeepLink: boolean;
    /**
     * #### defaultAutoSmartTitles
     *
     * If the settings is enabled and the top level settings `enableSmartTitles` should be enabled.
     * Enables auto mode by default for smart shift title
     */
    defaultAutoSmartTitles: boolean;
    defaultIsFlatRatePay: boolean;
    defaultIsRemoteShift: boolean;
    defaultSetup: unknown;
    disableCustomLocations: boolean;
    disableShiftAcceptAfterStart: boolean;
    /**  if `enableDurationAutoCheckout` is set, determines how long after checkin a worker should be auto checked out */
    durationAutoCheckout: number;
    /**
     * ### enableAddShiftWhenNoShow
     *
     * If this value is set, when a worker is marked as noShow with the markAsNoShow job,
     * adds an additional shift to allow additional shift pick up
     */
    enableAddShiftWhenNoShow: boolean;
    enableAsyncUploads: boolean;
    enableBulkNotifications: boolean;
    /**
     * ### enableBulkPushBackFlexibleShifts
     *
     * If this setting is enabled, will schedule job that reschedules flexible shifts
     * to later in the day if shifts aren't picked up or canceled last minute
     */
    enableBulkPushBackFlexibleShifts: boolean;
    enableBulkUpload: boolean;
    enableCheckinReminders: boolean;
    enableCheckinSurvey: boolean;
    /** Schedules job to auto checkout a user past a certain duration after checkin */
    enableDurationAutoCheckout: boolean;
    enableFlatRatePay: boolean;
    enableFlexShiftsView: boolean;
    enableFlexibleConflictRules: boolean;
    enableFlexibleShifts: boolean;
    /** Display the zone-aware locations filter on the shifts list page */
    enableNewZoneFilter: boolean;
    enablePastShiftCreation: boolean;
    enableRemoteShifts: boolean;
    enableShiftBonus: boolean;
    enableShiftCurrency: boolean;

    /**
     * ### enableSmartAutoNoShow
     *
     * If this value is set to true, marks unreliable workers that have GPS overrides and missing check in codes
     * as no show, even if they have already checked in
     */
    enableSmartAutoNoShow: boolean;

    // gives the ability to set the currency for a shift payment when it's created or edited
    /**
     * #### enableSmartTitles
     *
     * Enables auto generated smart shifts title for creating/updating a shift
     */
    enableSmartTitles: boolean;
    enableW2InstantPayouts: boolean;
    /**
     * ### excludeFromDashboardCalc
     * enable the option to exclude a specific shift from dashboard calcs.
     */
    excludeFromDashboardCalc: boolean;
    /**
     * ### firstShiftCreation
     * Enable marking a shift as first shift on creation.
     */
    firstShiftCreation: boolean;
    /** Auto checks out a user if they leave a geofence **/
    geofenceAutoCheckout: boolean;
    /** Maximum number of attendance violations a partner can have until their account will be disabled */
    maxAttendanceViolationCount: number;
    /**
     * ### minutesAfterStartToMarkNoShows
     *
     * If this value is set, sets how many minutes after the shift started before we mark the
     * partner's assignment's status as noShow.
     */
    minutesAfterStartToMarkNoShows: number;
    /**
     * Same as minutesAfterStartToMarkNoShows but for floaters on risky shifts
     */
    minutesAfterStartToMarkNoShowsForFloaters: number;
    /**
     * Same as minutesAfterStartToMarkNoShows but for floaters on mobile backup shifts
     */
    minutesAfterStartToMarkNoShowsForMobileBackup: number;
    /**
     * How many minutes after the shift started should we send a reminder about noshow possibility for floaters
     */
    minutesAfterStartToRemindNoShowsForFloaters: number;
    /**
     * How many minutes after the shift started should we send a reminder about noshow possibility
     * for floaters on mobile back up shifts
     */
    minutesAfterStartToRemindNoShowsForMobileBackup: number;

    newShiftsView: boolean;
    overtime: boolean;
    partnerCancelReasons: Array<string>;
    /**
     * ### preRFSAutoCancelOptions
     *
     * These settings control whether or not the partner gets auto canceled if they don't meet the requirements
     * N hours before shift start
     */
    preRFSAutoCancelOptions?: {
      bgPackageName: string;
      bgSubmittedTag: string;
      hoursAfterAcceptToSendReminder: number;
      hoursBeforeShiftToAutoCancel: number;
      isEnabled: boolean;
      trainingPassedTag: string;
    };
    /**
     * #### requireSavedLocationOnUpload
     *
     * If this setting is enabled, shifts that do not have a matching
     * saved location will be flagged during upload.
     */
    requireSavedLocationOnUpload: boolean;
    /**
     * ### requireShiftConfirmation
     *
     * Requires the worker to confirm their upcoming shifts in the app with a popup
     */
    requireShiftConfirmation: boolean;
    /**
     * ### requireShiftConfirmationReliabilityThreshold
     *
     * Sets the reliability threshold to require confirmation. If user is below this number, they will not be able to move on
     * in the app without confirming
     */
    requireShiftConfirmationReliabilityThreshold: number;
    /**
     * ### shiftType
     * Enable selection of a shift type when creating or editing a shift.
     */
    shiftType: boolean;
    /**
     * ### smartAutoNoShowDistanceThreshold
     *
     * Sets the distance threshold (distance in miles away from shift) that the user needs to meet
     * in order to not be marked no show when the user has not checked in
     * Used in conjunction with smartAutoNoShowLastActiveThreshold (see below)
     */
    smartAutoNoShowDistanceThreshold: string;
    /**
     * ### smartAutoNoShowLastActiveThreshold
     *
     * Sets the lastActiveAt threshold (minutes ago the user was lastActiveAt) that the user needs to meet
     * in order to not be marked no show when the user has not checked in
     * Used in conjunction with smartAutoNoShowDistanceThreshold (see above)
     */
    smartAutoNoShowLastActiveThreshold: string;
    /**
     * ### smartAutoNoShowReliabilityThreshold
     *
     * Sets the reliability threshold that the user needs to meet in order to pass unreliable check in
     * if not set, defaults to 0.5
     */
    smartAutoNoShowReliabilityThreshold: number;
  };
  /**
   * @deprecated use messagingNumber instead
   */
  smsNumbers: string[];
  staffing: {
    defaultAssignmentAction?: string;
    email?: string;
    enabledFulfillmentCompanies: string[];
    hideShiftsAsRequests?: boolean;
    phoneNumber?: string;
    /**
     * ## positionEntryMode
     * @default null
     * Determine how position or roles will be selected in the staffing request form
     *
     * ### null | 'standard'
     * Follow regular behavior
     *
     * ### 'adHoc'
     * Position/role will be a free form text entry field
     *
     * ### 'hybrid'
     * The role or position field can be free form text entry or dropdown
     *
     * ### 'disabled'
     * The position/role field will be hidden in the UI
     *
     * @type {('<no-setting or null>' | 'standard' | 'adHoc' | 'hybrid' | 'disabled')}
     */
    positionEntryMode?: null | 'standard' | 'adHoc' | 'hybrid' | 'disabled';
    shiftTitleTemplate?: string;
  };
  things: {
    consequencesEnabled: boolean;
    customCheckinDistance: number;
    /**
     * ### customCheckinTime
     * If set, sets how early, in minutes, before a shift starts that a worker can start checking into the shift
     * If not set, defaults to 120 minutes (2 hours)
     */
    customCheckinTime: string;
    customConfirmShiftTime: number;
    /**
     * ### customDisableCheckinDistance
     *
     * If set, disables workers from checking into the shift if they are outside specified radius from shift location
     * If not set, or set to 0, does not disable workers from checking into the shift
     * Distance is in miles
     */
    customDisableCheckinDistance: number;
    defaultExternalLinkText: string;
    /** Enables per-shift checkin codes on all new shifts (things) by default */
    defaultPerShiftAttendanceCodes?: boolean;
    defaultSMS?: boolean;
    disableCheckinCheckoutCodeUpdates?: boolean;
    disablePhotoAndGeolocation: boolean;
    disablePhotoLibraryUpload: boolean;
    displayTeammates: boolean;
    enableCheckInCheckOut: boolean;
    /**
     * #### enableCheckinCodeByDefault
     *
     * When creating a new shift, will checkin codes be enabled by default.
     *
     * If a shift is filed on behalf of a managed (requesting) company, that company's setting will have
     * priority over the value of the managing (staffing) company.
     */
    enableCheckinCodeByDefault: boolean;
    enableEarlyCheckout: boolean;
    enableExternalLink: boolean;
    enableManagerCancelReason: boolean;
    enableManualShiftReminders: boolean;
    /**
     * #### enableOnsiteCheckinCode
     *
     * Enables shifts to have unique codes generated for enhanced onsite checkin validation
     * Determine wheather belonging things/shifts of the company can use the checkin code for the partners
     */
    enableOnsiteCheckinCode: boolean;
    /**
     * #### enableOnsiteCheckoutCode
     *
     * Enables shifts to have unique codes generated for enhanced onsite checkout validation
     * Determine whether belonging things/shifts of the company can use the checkout code for the partners
     */
    enableOnsiteCheckoutCode: boolean;
    enablePartnerCancel: boolean;
    enablePartnerCancelReaccept: boolean;
    enablePartnerCancelReason: boolean;
    enablePartnerShiftReview: boolean;
    enablePhotoUploadRequirement: boolean;
    enableSendSMS: boolean;
    enableShiftBlock: boolean;
    enableShiftGroupMessages: boolean;
    enableShiftSwaps: boolean;
    /** Allows shifts to be configured with unique codes per-shift, instead of the standard per-location/day tuple */
    enableUniqueAttendanceCodes?: boolean;
    ignoreShiftConflicts: boolean;
    includeManagerCancelReasonInPN: boolean;
    /**
     * #### maxOffsiteCheckinOverrides
     *
     * Determines the maximum number of times a partner can bypass the checkin code requirement offsite before feature is disabled for them
     */
    maxOffsiteCheckinOverrides: number;
    requireDescription: boolean;
    requireRate: boolean;
    sendActivationInBulk: boolean;
    sendUpdatePN: boolean;

    sendUpdateSMS: boolean;
    shiftBlockMax: unknown;
    showBadgeOnCard: boolean;
    showStoreNumberOnCard: boolean;
  };
  timekeeping: {
    breakOptions: Array<{
      breakCategory: 'paidBreak' | 'unpaidBreak';
      breakDuration: number;
    }>;
    breakRequirements: unknown;
    employerEditDisabled: boolean;

    /**
     * Enables the auto-approval workflow after an assignment is completed
     */
    enableAutoApprovals?: boolean;

    /**
     * BETA: Billable intervals will set the assignment to active/in-progress when they
     * have checked in or been marked as active. This logic is in BETA and subject to change
     */
    enableBillableIntervals?: boolean;

    /**
     * When enabled, the ops/managers/employer can update the time keeping status after
     * the shift/staffing request is being completed. When the staffing request/shift is
     * being completed, they can approve/denied/contested the workers duration of working
     * hours
     */
    enableTimecardApprovals: boolean;
    markUsersNoShowAtEndOfDay: boolean;
    markUsersNoShowTimezone: string;
    partnerDisabled: boolean;
    showStats: boolean;
    useForPay: boolean;
  };
  webhooks: {
    assignmentsUpdates: {
      authTokenCypher: string;
      fields: string[];
      url: string;
    };
    shiftsUpdates: {
      authTokenCypher: string;
      fields: string[];
      url: string;
    };
  };

  zones: {
    tiers: [Tier, Tier, Tier, Tier, Tier];
  };
}>;

export const redundancyTypeOptions = ['backup', 'redundant', 'shadow'] as const;
export type RedundancyType = (typeof redundancyTypeOptions)[number];

export type OverrideRule = {
  maxReliabilityScore?: number;
  maxShiftsWorked?: number;
  minReliabilityScore?: number;
  minShiftsWorked?: number;
};

export type RedundancySettings = PartialDeep<{
  autoCreateFloaterSettings: {
    dismissalPayMarkdown: number;
    floaterDispatchPref: string;
    floaterShiftTitle: string;
    floatersStartTimeBufferMinutes?: number;
    linearTransformConfig: {
      individualFFRBuffer: number;
      individualFFRFloor: number;
      numFloatersAdditiveBuffer: number;
      numFloatersMultiplier: number;
    };
  };
  autoCreateRedundantShifts: {
    disableCheckinCheckoutCodeUpdates: boolean;
    increaseFillRateDispatchPref: IDispatchPref['uuid'];
    increaseFillRateRedundancyType: RedundancyType;
    isEnabled: boolean;
    maximumFFRForDupeCreationDecimalPercentage: number;
    maximumHighRiskRedundancyDupePercentage: number;
    minimumFillRateForDupeCreationDecimalPercentage: number;
    overrideRules: OverrideRule[];
    useDailyFillRateCalculation: boolean; // Used for migrating to daily Fill Rate version of duper
  };
  backupSettings: {
    backupDispatchPrefId: IDispatchPref['uuid'];
    dismissalPayMarkdown: number;
    offsetShiftStartMinutes: number;
  };
  shadowSettings: {
    shadowDispatchPrefId: IDispatchPref['uuid'];
  };
}>;

/**
 * GuardRail is a set of rules that are used to validate the data.
 */
export type GuardRail = {
  maxAbsValueForWindow?: number;
  maxAbsValuePerItemMajor?: number;
  maxDecimalPercentageForWindow?: number;
  timeHorizonMinutes: number;
};

export type BonusConfig = PartialDeep<{
  dynamicBonus: DynamicBonusCompanyConfig & {
    guardrail: GuardRail;
  };
  manualBonus: {
    /**
     * Bonus can only be added:
     *  after shift starts when positive,
     *  before shift starts when negative,
     *  anytime when undefined.
     */
    allowFromShiftStartTimeInMinutes: number;
    /**
     * By default manual bonus is enabled, disable when necessary
     */
    disabled: boolean;
  };
}>;

export type CoreCompany = Pick<
  ICompany,
  | 'uuid'
  | 'name'
  | 'abbr'
  | 'modules'
  | 'localeInfo'
  | 'address'
  | 'loc'
  | 'formattedAddress'
  | 'settings'
>;
